export default function attachAtlas(key?: string) {
  var t,
    e = {
      appId: key || 'q4mjj4och5',
      v: 2,
      q: [] as any,
      identify: function (t: any) {
        this.q.push(['identify', t])
      },
      start: function (t: any) {
        ;(this as any).autorun = t || !0
      },
    } as any
  ;(window as any).Atlas = e
  const n = document.createElement('script')
  n.async = !0
  n.src = 'https://app.getatlas.io/client-js/atlas.bundle.js'
  var s = document.getElementsByTagName('script')[0]
  null === (t = s.parentNode) || void 0 === t || t.insertBefore(n, s)
}
