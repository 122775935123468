import moment from 'moment'
import { OrderDataInterface } from './types'

function shouldBuffer(data: OrderDataInterface) {
  if (!data?.fulfillment?.runner_picked_up) {
    return true
  }
  return false
}

export const formatEstimatedTime = (data: OrderDataInterface) => {
  const { runner_estimated_dropoff } = data?.fulfillment!
  const moment_time = moment(runner_estimated_dropoff)
  if (shouldBuffer(data)) {
    moment_time.add(
      data?.merchant?.tracker_delivery_buffer,
      'minutes'
    )
  }
  if (runner_estimated_dropoff) {
    return moment_time.format('hh:mm a')
  }
  return false
}

export const formatEstimatedRange = (data: OrderDataInterface) => {
  const { runner_estimated_dropoff } = data?.fulfillment || {}

  if (!runner_estimated_dropoff) {
    return false // If no estimated dropoff time is available, return false
  }

  const now = moment() // Current time
  const dropoffTime = moment(runner_estimated_dropoff)
  if (shouldBuffer(data)) {
    dropoffTime.add(
      data?.merchant?.tracker_delivery_buffer,
      'minutes'
    )
  }
  let minutesUntilDropoff = dropoffTime.diff(now, 'minutes')

  // If the time until dropoff is negative (i.e., in the past), set a minimum window starting from now
  if (dropoffTime < now) {
    minutesUntilDropoff = 10 // Start counting from now
  }

  // Define the end of the window as 5 minutes after the minutes until dropoff
  const windowEnd = minutesUntilDropoff + 5

  // Return the time window in the format "X - Y minutes"
  return `${minutesUntilDropoff} - ${windowEnd} minutes`
}
